import React from "react";
import { motion, useInView } from "framer-motion";
import image1 from "../assets/layout-1/image-1.jpg";
import image2 from "../assets/layout-1/image-2.jpg";
import image3 from "../assets/layout-1/image-3.jpg";
import logo from "../assets/layout-1/logo-icon.png";
import {
  containerVariants,
  imageLeftVariants,
  imageRightVariants,
  itemVariants,
} from "../animations/animations";

const Section1: React.FC = () => {
  const textRef = React.useRef(null);
  const imageRef = React.useRef(null);

  // Trigger animations when elements come into view
  const isTextInView = useInView(textRef, { once: true, amount: 0.3 });
  const isImageInView = useInView(imageRef, { once: true, amount: 0.3 });

  return (
    <div className="flex justify-center items-center min-h-full">
      <div className="flex flex-col-reverse md:flex-row mx-4 md:mx-12 pt-12 md:pt-20">
        {/* Text content section */}
        <motion.div
          ref={textRef}
          className="flex-1 md:pr-9 mt-6 md:mt-2"
          initial="hidden"
          animate={isTextInView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          {/* Heading */}
          <motion.h1
            className="font-prata text-[28px] md:text-[36px] leading-[40px] md:leading-[50px] text-[#302c51] 
            mb-4 md:mb-6 font-normal"
            variants={itemVariants}
          >
            Be part of a change
            <br />
            you want to see in the world
          </motion.h1>

          {/* Blockquote */}
          <motion.blockquote
            className="text-[16px] md:text-[18px] text-[#ed6221] mb-4 md:mb-6"
            variants={itemVariants}
          >
            "Generosity consists not of the sum given, but the manner in which
            it is bestowed."
          </motion.blockquote>

          {/* Paragraphs */}
          <motion.p
            className="text-[#858585] mb-3 md:mb-4 text-[14px] md:text-[15px] leading-[24px] md:leading-[28px] font-normal text-justify"
            variants={itemVariants}
          >
            VALUE WINGS FOUNDATION is a Section 8 Non-Profit Organization (NPO)
            established in June 2024. Our primary focus is addressing various
            social needs in India including education, health, social, cultural,
            and other activities.
          </motion.p>
          <motion.p
            className="text-[#858585] text-[14px] md:text-[15px] leading-[24px] md:leading-[28px] font-normal text-justify"
            variants={itemVariants}
          >
            We aim to transform the delivery and execution of these services
            across the nation by implementing innovative and impactful changes.
          </motion.p>
        </motion.div>

        {/* Image section */}
        <motion.div
          ref={imageRef}
          className="flex-1 relative"
          initial="hidden"
          animate={isImageInView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          {/* Image container */}
          <div className="relative max-w-[600px] h-[280px] sm:h-[400px] md:h-[430px]">
            {/* Left image */}
            <motion.img
              src={image1}
              alt="Children in India 1"
              className="absolute left-0 w-[45%] sm:w-[40%] md:w-[237px] h-[85%] sm:h-[90%] md:h-[408px] object-cover rounded-lg z-10"
              variants={imageLeftVariants}
            />
            {/* Center image */}
            <img
              src={image2}
              alt="Children in India 2"
              className="absolute left-1/2 -translate-x-1/2 top-4 sm:top-6 md:top-8 w-[50%] sm:w-[45%] md:w-[263px] h-[70%] sm:h-[75%] md:h-[350px] object-cover rounded-lg z-20"
            />
            {/* Right image */}
            <motion.img
              src={image3}
              alt="Children in India 3"
              className="absolute right-0 w-[44%] sm:w-[39%] md:w-[232px] h-[65%] sm:h-[70%] md:h-[308px] top-[18%] sm:top-[20%] md:top-[85px] object-cover rounded-lg z-10"
              variants={imageRightVariants}
            />
          </div>

          {/* Logo section */}
          <div className="absolute top-2 right-2 sm:top-3 sm:right-3 md:top-4 md:right-12 z-30 flex items-center justify-center">
            <div className="relative">
              {/* Outer white circle */}
              <div className="bg-white rounded-full p-1 sm:p-1.5 md:p-2 w-[120px] h-[120px] sm:w-[140px] sm:h-[140px] md:w-[160px] md:h-[160px]"></div>
              {/* Inner white circle */}
              <div className="absolute top-1/2 bg-white left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full p-1 sm:p-1.5 md:p-2 w-[100px] h-[100px] sm:w-[120px] sm:h-[120px] md:w-[140px] md:h-[140px] flex items-center justify-center shadow-[0_0_7px_rgba(0,0,0,0.1)]">
                {/* Rotating dotted border */}
                <motion.div
                  className="absolute inset-[6px] sm:inset-[8px] md:inset-[10px] border-[3px] border-dotted border-[#ed6221] rounded-full"
                  animate={{ rotate: 360 }}
                  transition={{
                    duration: 10,
                    repeat: Infinity,
                    ease: "linear",
                  }}
                ></motion.div>
                {/* Logo image */}
                <img
                  src={logo}
                  alt="Value Wings Foundation Logo"
                  className="w-[60px] h-[44px] md:w-[75px] md:h-[55px] object-contain relative z-10"
                />
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Section1;
