import React from "react";
import decorImage from "../assets/causes-img/decor-2.png";
import cause1 from "../assets/causes-img/cause-1.jpg";
import cause2 from "../assets/causes-img/cause-2.jpg";
import cause3 from "../assets/causes-img/cause-3.jpg";
import { FaBook } from "react-icons/fa";
import { FaAppleAlt } from "react-icons/fa";
import { PiHeartbeatFill } from "react-icons/pi";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { createSlideAnimation } from "../animations/animations";
import { motion } from "framer-motion";

const causesData = [
  {
    title: "Help Spread Awareness of Education",
    category: "Education",
    description:
      "Ensuring that more individuals are informed about educational opportunities, rights, and resources available to them",
    imageUrl: cause1,
    icon: FaBook,
  },
  {
    title: "Better Nutrition, Better Future",
    category: "Food & Nutrition",
    description:
      "Join us in our mission to reduce malnutrition and promote healthier eating habits by providing education, resources, and support for improved dietary choices and overall well-being.",
    imageUrl: cause2,
    icon: FaAppleAlt,
  },
  {
    title: "Help care takers achieve their dream of working abroad",
    category: "Health & Diseases",
    description:
      "Helping caregivers, nurses, or similar professions realize their goal of finding employment opportunities in foreign countries",
    imageUrl: cause3,
    icon: PiHeartbeatFill,
  },
  {
    title: "Help Spread Awareness of Education",
    category: "Education",
    description:
      "Ensuring that more individuals are informed about educational opportunities, rights, and resources available to them",
    imageUrl: cause1,
    icon: FaBook,
  },
  {
    title: "Better Nutrition, Better Future",
    category: "Food & Nutrition",
    description:
      "Join us in our mission to reduce malnutrition and promote healthier eating habits by providing education, resources, and support for improved dietary choices and overall well-being.",
    imageUrl: cause2,
    icon: FaAppleAlt,
  },
  {
    title: "Help care takers achieve their dream of working abroad",
    category: "Health & Diseases",
    description:
      "Helping caregivers, nurses, or similar professions realize their goal of finding employment opportunities in foreign countries",
    imageUrl: cause3,
    icon: PiHeartbeatFill,
  },
];

const Causes: React.FC = () => {
  const backgroundStyle = {
    backgroundImage: `url(${decorImage})`,
    backgroundSize: "auto",
    height: "100%",
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#F4F1EE",
    paddingTop: "100px",
    paddingBottom: "70px",
    backgroundPosition: "left top 50px",
  };

  return (
    <div style={backgroundStyle}>
      <div className="flex justify-center flex-col items-center px-4 md:px-8 lg:px-16">
        <motion.h1
          className="font-prata text-[#302c51] text-[24px] md:text-[30px] lg:text-[36px] leading-[32px] md:leading-[40px] lg:leading-[50px] font-normal text-center"
          {...createSlideAnimation("bottom", 0.2)}
        >
          Causes we care about
        </motion.h1>
        <motion.p
          className="text-[#858585] text-[14px] md:text-[16px] leading-[24px] md:leading-[28px] font-light mt-4 text-center"
          {...createSlideAnimation("bottom", 0.4)}
        >
          We connect nonprofits, donors, and companies in nearly every country
          around the world.
        </motion.p>
      </div>

      <motion.div
        className="mt-10 px-4 sm:px-6 md:px-8"
        {...createSlideAnimation("bottom", 0.6)}
      >
        <Swiper
          modules={[Autoplay]}
          slidesPerView={1}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 2,
            },
            1200: {
              slidesPerView: 3,
            },
          }}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          loop={true}
        >
          {causesData.map((cause, index) => {
            const IconComponent = cause.icon;
            return (
              <SwiperSlide key={index}>
                <div className="flex justify-center items-center cursor-pointer ">
                  <div className="max-w-[370px] rounded-lg  bg-white flex flex-col justify-center items-center pb-6">
                    <div className="w-[330px] relative py-4 ">
                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                        <div className="w-[330px] relative mx-auto h-auto overflow-hidden rounded-lg">
                          <img
                            src={cause.imageUrl}
                            alt={cause.title}
                            className="w-full h-auto relative z-0 rounded-lg transition-all duration-500 hover:scale-110"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="px-10 py-4">
                      <div className="font-normal text-[20px] leading-[24px] mb-2 hover:text-[#ed6221] transition-colors duration-300 delay-200">
                        {cause.title}
                      </div>
                      <div className="flex items-center text-[#a6a6a6] text-[15px] leading-[28px] mb-2 font-normal underline hover:text-[#ed6221] transition-colors duration-300 delay-200">
                        <span className="mr-2">
                          <IconComponent className="w-[18px] h-[18px]" />
                        </span>
                        {cause.category}
                      </div>
                      <p className="text-[#858585] text-[15px] font-normal leading-[28px]">
                        {cause.description}
                      </p>
                      <div className="max-w-[172px] mt-2 sm:mt-4 flex justify-center items-center bg-transparent border-2 border-[#ed6221] rounded-full relative overflow-hidden group py-3">
                        <span className="relative z-10 text-[#ed6221] group-hover:text-white transition-colors duration-300 text-[13px] font-[500] leading-[24px]">
                          DONATE NOW
                        </span>
                        <span className="absolute inset-0 bg-[#ed6221] transform -translate-y-full group-hover:translate-y-0 transition-transform duration-300"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </motion.div>
    </div>
  );
};

export default Causes;
