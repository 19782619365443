import React from "react";
import Image1 from "../assets/ourTeam/Rajkumar Sambandam.jpg";
import Image2 from "../assets/ourTeam/Asha S.L.jpg";
import { motion } from "framer-motion";
import { createSlideAnimation } from "../animations/animations";

interface TeamMember {
  image: string;
  name: string;
  role: string;
  description1: string;
  description2: string;
  description3: string;
}

const teamMembers: TeamMember[] = [
  {
    image: Image1,
    name: "Rajkumar Sambandam",
    role: "Founder & Chairman",
    description1:
      '"A visionary leader with many years of experience as an entrepreneur in diversified industries globally."',
    description2:
      "As a founder and Chairman of VALUE WINGS FOUNDATION, Rajkumar Sambandam has been the driving force behind our mission to “Provide accessible and affordable education to underprivileged communities & elevating individuals with skills & Knowledge to improve their livelihood.",
    description3:
      "Rajkumar Sambandam's passion for social change and dedication to community development has been instrumental in shaping the strategic direction of our organization",
  },
  {
    image: Image2,
    name: "Asha S.L",
    role: "Chief Executive Officer (CEO)",
    description1:
      '"Bringing a wealth of experience and expertise to our organization."',
    description2:
      "With a background in Business Development and CSR fundraising is dedicated to advancing our vision “Transforming lives and enriching communities by fostering essential skills, ensuring families can support themselves with honor and aspire to a brilliant future.",
    description3:
      "With her visionary leadership and unwavering commitment to social impact, Asha is pivotal in driving VALUE WINGS FOUNDATION towards achieving its goals and making a lasting difference in the community.",
  },
];

const OurTeam: React.FC = () => {
  return (
    <div className="px-4 py-14 sm:py-16 md:py-20 lg:py-24 flex justify-center">
      <div className="max-w-6xl w-full">
        <motion.h2
          className="text-2xl sm:text-3xl md:text-[36px] font-normal leading-tight sm:leading-snug md:leading-[43px] text-[#302c51] font-prata text-center mb-10 sm:mb-16 md:mb-20"
          {...createSlideAnimation("left", 0.2)}
        >
          Meet Our Team
        </motion.h2>
        <motion.div
          className="grid grid-cols-1 lg:grid-cols-2 gap-8 justify-items-center"
          {...createSlideAnimation("bottom", 0.2)}
        >
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-[0_0_10px_rgba(237,98,33,0.3)] overflow-hidden w-full"
            >
              <div className="flex justify-center pt-4 sm:pt-6 md:pt-7 px-4">
                <img
                  src={member.image}
                  alt={member.name}
                  className="w-full max-w-[250px] sm:max-w-[300px] md:max-w-[350px] h-auto aspect-square object-cover border-[1px] border-[#cbcbcb]"
                />
              </div>
              <div className="p-4 sm:p-5 md:p-6">
                <motion.h3
                  className="text-xl sm:text-2xl md:text-[24px] font-normal leading-tight sm:leading-snug md:leading-[29px] text-[#ed6221] mb-2 text-center"
                  {...createSlideAnimation("left", 0.4)}
                >
                  {member.name}
                </motion.h3>
                <motion.p
                  className="text-base sm:text-lg md:text-[18px] font-normal leading-tight sm:leading-snug md:leading-[22px] text-[#666] font-prata mb-4 sm:mb-5 md:mb-6 text-center"
                  {...createSlideAnimation("left", 0.4)}
                >
                  {member.role}
                </motion.p>
                {[
                  member.description1,
                  member.description2,
                  member.description3,
                ].map((desc, i) => (
                  <motion.p
                    key={i}
                    className="text-sm sm:text-base md:text-[16px] font-normal leading-relaxed sm:leading-relaxed md:leading-[26px] text-[#444] mb-4 sm:mb-5 md:mb-6"
                    {...createSlideAnimation("bottom", 0.4)}
                  >
                    {desc}
                  </motion.p>
                ))}
              </div>
            </div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default OurTeam;
