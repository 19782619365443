import React from "react";
import TopBar from "../component/TopBar";
import NavBar from "../component/NavBar";
import AboutUs from "../component/AboutUs";
import ContactUs from "../component/Contact";
import Footer from "../component/Footer";
import OurTeam from "../component/OurTeam";

const AboutUsPage: React.FC = () => {
  return (
    <div>
      <TopBar />
      <NavBar />
      <AboutUs />
      <OurTeam />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default AboutUsPage;
