import React from "react";
import TopBar from "../component/TopBar";
import NavBar from "../component/NavBar";
import ContactUs from "../component/Contact";
import Footer from "../component/Footer";

const ContactPage: React.FC = () => {
  return (
    <div>
      <TopBar />
      <NavBar />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default ContactPage;
