import { motion } from "framer-motion";
import React from "react";
import { IoPersonSharp } from "react-icons/io5";
import { createSlideAnimation } from "../animations/animations";
import HeartIcon from "../assets/heart-icon.png";

const AboutUs: React.FC = () => {
  return (
    <div className="pb-8 sm:pb-6 md:pb-12">
      {/* Top Section */}
      <div className="relative bg-[#2A2550] text-white py-16 sm:py-24">
        <div className="text-center mb-8 sm:mb-12">
          <motion.h1
            className="font-prata font-normal text-2xl sm:text-3xl md:text-4xl lg:text-[48px] leading-tight sm:leading-[58px]"
            {...createSlideAnimation("bottom", 0.2)}
          >
            Know About Us
          </motion.h1>
        </div>

        <div className="absolute w-[180px] sm:w-[205px] h-[50px] sm:h-[63px] bottom-0 left-4 sm:left-6 md:left-11 transform translate-y-1/2 bg-white py-2 px-3 sm:px-4 rounded shadow-md flex items-center justify-center">
          <span className="text-[#ff6b35] mr-2 text-xl sm:text-[24px]">
            <IoPersonSharp />
          </span>
          <div className="w-px h-6 sm:h-7 bg-[#EAEAEA] mx-3 sm:mx-4"></div>
          <span className="font-normal text-sm sm:text-[16px] leading-[24px] sm:leading-[28px] text-[#1d1747e6] mt-2">
            About Us
          </span>
        </div>
      </div>

      {/* About Us Section */}
      <div className="container mx-auto px-4 mt-16 sm:mt-16">
        <div className="text-center mb-4">
          <motion.img
            src={HeartIcon}
            alt="Heart Icon"
            className="mx-auto w-auto h-16 mb-4 sm:mb-5"
            {...createSlideAnimation("bottom", 0.2)}
          />
          <motion.h2
            className="font-prata text-2xl sm:text-3xl md:text-[36px] leading-tight sm:leading-[43px] font-normal text-[#302c51] mb-16 sm:mb-24"
            {...createSlideAnimation("bottom", 0.2)}
          >
            Who Are we?
          </motion.h2>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 px-3 sm:px-6 md:px-9">
          <motion.div
            className="mb-0 md:mb-7"
            {...createSlideAnimation("left", 0.4)}
          >
            <h3 className="font-prata text-lg sm:text-xl md:text-2xl lg:text-[22px] leading-tight sm:leading-snug md:leading-normal lg:leading-[26px] font-bold text-[#ed6221] mb-4">
              VALUE WINGS FOUNDATION
            </h3>
            <p className="text-[#302c51] text-[15px] leading-[28px] font-normal">
              A Section 8 Non-Profit Organization (NPO) established in June
              2024. Our primary focus is addressing various social needs in
              India including education, health, social, cultural, and other
              activities.
            </p>
          </motion.div>

          <motion.div
            className="mb-0 md:mb-7"
            {...createSlideAnimation("right", 0.4)}
          >
            <h3 className="font-prata text-lg sm:text-xl md:text-2xl lg:text-[22px] leading-tight sm:leading-snug md:leading-normal lg:leading-[26px] font-bold text-[#302c51] mb-4">
              WE AIM TO
            </h3>
            <p className="text-[#302c51] text-[15px] leading-[28px] font-normal">
              Transform the delivery and execution of these services across the
              nation by implementing innovative and impactful changes.
            </p>
          </motion.div>

          <motion.div
            className="mb-0 md:mb-7"
            {...createSlideAnimation("left", 0.6)}
          >
            <h3 className="font-prata text-lg sm:text-xl md:text-2xl lg:text-[22px] leading-tight sm:leading-snug md:leading-normal lg:leading-[26px] font-bold text-[#FF6B35] mb-4">
              OUR VISION
            </h3>
            <p className="text-[#302c51] text-[15px] leading-[28px] font-normal">
              Transforming lives and enriching communities by fostering
              essential skills, ensuring families can support themselves with
              honor and aspire to a brilliant future.
            </p>
          </motion.div>

          <motion.div
            className="mb-0 md:mb-7"
            {...createSlideAnimation("right", 0.6)}
          >
            <h3 className="font-prata text-lg sm:text-xl md:text-2xl lg:text-[22px] leading-tight sm:leading-snug md:leading-normal lg:leading-[26px] font-bold text-[#302c51] mb-4">
              OUR MISSION
            </h3>
            <p className="text-[#302c51] text-[15px] leading-[28px] font-normal">
              Provide accessible and affordable education to under privileged
              communities & elevating individuals with skills & Knowledge to
              improve their livelihood
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
