import React from "react";
import { IoMdMail } from "react-icons/io";
import { MdPhoneInTalk } from "react-icons/md";

const TopBar: React.FC = () => {
  return (
    <div className="bg-[#ed6221] px-4 sm:px-6 md:px-8 lg:px-[47px] py-3 sm:py-4 lg:py-[14px] text-white flex flex-col sm:flex-row justify-between items-center">
      {/* Donation message */}
      <p className="text-sm sm:text-[15px] mb-2 sm:mb-0 text-center sm:text-left">
        We only have what we give... Donate Now.
      </p>

      {/* Contact information and social media icons container */}
      <div className="flex flex-col sm:flex-row items-center text-xs sm:text-sm md:text-[15px] font-normal">
        {/* Email contact */}
        <div className="flex items-center mb-2 sm:mb-0">
          <IoMdMail className="mr-2 text-base sm:text-[20px]" />
          <a href="mailto:contact@valuewings.com" className="mr-4 no-underline">
            contact@valuewings.com
          </a>
        </div>

        <div className="hidden sm:block h-4 sm:h-6 w-[0.1px] bg-[#ff8e59] mx-2"></div>

        {/* Phone contact */}
        <div className="flex items-center mb-2 sm:mb-0">
          <MdPhoneInTalk className="mx-2 text-base sm:text-[20px]" />
          <a href="tel:+919035053109" className="mr-4 no-underline">
            +91 90350 53109
          </a>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
