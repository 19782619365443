import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/logo.png";
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("/");

  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const sections = document.querySelectorAll("section");
      const homeSection = document.querySelector("main");

      if (homeSection && scrollPosition < homeSection.clientHeight / 2) {
        setActiveLink("/");
        return;
      }

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (
          scrollPosition >= sectionTop - 100 &&
          scrollPosition < sectionTop + sectionHeight - 100
        ) {
          const id = section.getAttribute("id");
          setActiveLink(id ? `#${id}` : "/");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setActiveLink(window.location.pathname);
  }, []);

  const handleLinkClick = (href: string) => {
    setActiveLink(href);
    setIsOpen(false);
    if (href === "#causes") {
      navigate("/", { state: { scrollTo: "causes" } });
    }
  };

  const links = [
    { href: "/", label: "Home" },
    { href: "/about-us", label: "About Us" },
    { href: "#causes", label: "Causes" },
    { href: "/key-moments", label: "Key Moments" },
    { href: "/contact-us", label: "Contact" },
  ];

  return (
    <div>
      <nav className="bg-white shadow-md">
        <div className="container mx-auto px-4 py-6 flex justify-between items-center">
          {/* Logo */}
          <div className="flex items-center ml-2 sm:ml-8">
            <img
              src={Logo}
              alt="Logo"
              className="w-[150px] h-auto sm:w-[200px]"
            />
          </div>

          {/* Hamburger Button */}
          <div className="lg:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-[#ed6221] focus:outline-none"
            >
              {isOpen ? (
                <FaTimes className="w-6 h-6" />
              ) : (
                <FaBars className="w-6 h-6" />
              )}
            </button>
          </div>

          {/* Menu Items */}
          <div className="hidden lg:flex space-x-4 xl:space-x-8 items-center">
            {links.map((link) =>
              link.href.startsWith("#") ? (
                <a
                  key={link.href}
                  href={link.href}
                  className={`hover:text-[#ed6221] font-[500] relative pb-1 ${
                    activeLink === link.href
                      ? "text-[#ed6221]"
                      : "text-gray-700"
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleLinkClick(link.href);
                  }}
                >
                  {link.label}
                  <span className="absolute bottom-0 left-0 right-0 flex flex-col items-center">
                    <span
                      className={`block h-[2px] w-[25px] bg-[#ed6221] mb-[2px] transition-transform duration-300 ${
                        activeLink === link.href ? "scale-x-100" : "scale-x-0"
                      }`}
                    ></span>
                    <span
                      className={`block h-[2px] w-[40px] bg-[#ed6221] transition-transform duration-300 ${
                        activeLink === link.href ? "scale-x-100" : "scale-x-0"
                      }`}
                    ></span>
                  </span>
                </a>
              ) : (
                <Link
                  key={link.href}
                  to={link.href}
                  className={`hover:text-[#ed6221] font-[500] relative pb-1 ${
                    activeLink === link.href
                      ? "text-[#ed6221]"
                      : "text-gray-700"
                  }`}
                  onClick={() => handleLinkClick(link.href)}
                >
                  {link.label}
                  <span className="absolute bottom-0 left-0 right-0 flex flex-col items-center">
                    <span
                      className={`block h-[2px] w-[25px] bg-[#ed6221] mb-[2px] transition-transform duration-300 ${
                        activeLink === link.href ? "scale-x-100" : "scale-x-0"
                      }`}
                    ></span>
                    <span
                      className={`block h-[2px] w-[40px] bg-[#ed6221] transition-transform duration-300 ${
                        activeLink === link.href ? "scale-x-100" : "scale-x-0"
                      }`}
                    ></span>
                  </span>
                </Link>
              )
            )}
            <a
              href="/"
              className="px-8 py-2 border-2 border-[#ed6221] rounded-full font-[500] text-[13px] relative overflow-hidden group"
            >
              <span className="relative z-10 text-[#ed6221] group-hover:text-white transition-colors duration-300">
                VOLUNTEER
              </span>
              <span className="absolute inset-0 bg-[#ed6221] transform -translate-y-full group-hover:translate-y-0 transition-transform duration-300"></span>
            </a>
          </div>
        </div>
      </nav>

      {/* Mobile View Menu Items */}
      <div
        className={`lg:hidden bg-white shadow-md overflow-hidden transition-all duration-700 ease-in-out ${
          isOpen ? "max-h-96 opacity-100" : "max-h-0 opacity-0"
        }`}
      >
        <div className="flex flex-col space-y-4 py-4 px-8">
          {links.map((link) =>
            link.href.startsWith("#") ? (
              <a
                key={link.href}
                href={link.href}
                className={`hover:text-[#ed6221] font-[500] ${
                  activeLink === link.href ? "text-[#ed6221]" : "text-gray-700"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  handleLinkClick(link.href);
                }}
              >
                {link.label}
              </a>
            ) : (
              <Link
                key={link.href}
                to={link.href}
                className={`hover:text-[#ed6221] font-[500] ${
                  activeLink === link.href ? "text-[#ed6221]" : "text-gray-700"
                }`}
                onClick={() => handleLinkClick(link.href)}
              >
                {link.label}
              </Link>
            )
          )}
          <button className="px-6 py-2 border-2 border-[#ed6221] rounded-full font-[500] text-[15px] relative overflow-hidden group inline-block text-[#ed6221] uppercase hover:text-white hover:bg-[#ed6221]">
            <a href="/">Volunteer</a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
