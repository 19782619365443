import React from "react";
import {
  FaTwitter,
  FaArrowRight,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaHeart,
  FaPhoneAlt,
} from "react-icons/fa";
import Logo from "../assets/layout-1/logo-icon.png";
import { IoMdMail } from "react-icons/io";

const Footer = () => {
  return (
    <footer className="bg-[#2D294D] pt-24 pb-8 px-4 md:px-12">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 pb-10">
          {/* Contact Section */}
          <div>
            <h2 className="text-[20px] font-medium leading-[24px] text-white mb-8">
              Contact
            </h2>
            <p className="font-normal text-[15px] leading-[28px] text-[#9b99ad] mb-px">
              JP Chambers, No 276/22/1, 46th
            </p>
            <p className="font-normal text-[15px] leading-[28px] text-[#9b99ad] mb-px">
              Cross,
            </p>
            <p className="font-normal text-[15px] leading-[28px] text-[#9b99ad] mb-px">
              5th Block Jayanagar
            </p>
            <p className="font-normal text-[15px] leading-[28px] text-[#9b99ad] mb-px">
              Bengaluru 560041, Karnataka
            </p>
            <p className="font-normal text-[15px] leading-[28px] text-[#9b99ad] mb-px flex items-center">
              <IoMdMail className="mr-2 text-[#9b99ad]" />
              <a
                href="mailto:contact@valuewings.com"
                className="text-[#9b99ad] hover:text-[#ED6221] transition-colors duration-300"
              >
                contact@valuewings.com
              </a>
            </p>
            <p className="text-[24px] font-normal text-white leading-[29px] mt-4 flex items-center">
              <FaPhoneAlt className="mr-2 text-white" />
              <a href="tel:+919035053109">+91 90350 53109</a>
            </p>
          </div>

          {/* About Section */}
          <div>
            <h2 className="text-[20px] font-medium leading-[24px] text-white mb-5">
              About
            </h2>
            <ul className="flex flex-col space-y-2 -space-x-4">
              <li></li>
              <li className="group flex items-center font-normal text-[15px] leading-[28px] text-[#9b99ad] transition-transform duration-700">
                <FaHeart className="text-transparent transition-colors duration-700 group-hover:text-[#ED6221]" />
                <a
                  href="/"
                  className="text-[#9b99ad] group-hover:translate-x-2 transition-transform duration-700 hover:text-white"
                >
                  Charity
                </a>
              </li>
              <li className="group flex items-center font-normal text-[15px] leading-[28px] text-[#9b99ad] transition-transform duration-700">
                <FaHeart className="text-transparent transition-colors duration-700 group-hover:text-[#ED6221]" />
                <a
                  href="/"
                  className="text-[#9b99ad] group-hover:translate-x-2 transition-transform duration-700 hover:text-white"
                >
                  Volunteers
                </a>
              </li>
              <li className="group flex items-center font-normal text-[15px] leading-[28px] text-[#9b99ad] transition-transform duration-700">
                <FaHeart className="text-transparent transition-colors duration-700 group-hover:text-[#ED6221]" />
                <a
                  href="/"
                  className="text-[#9b99ad] group-hover:translate-x-2 transition-transform duration-700 hover:text-white"
                >
                  Upcoming Events
                </a>
              </li>
              <li className="group flex items-center font-normal text-[15px] leading-[28px] text-[#9b99ad] transition-transform duration-700">
                <FaHeart className="text-transparent transition-colors duration-700 group-hover:text-[#ED6221]" />
                <a
                  href="/"
                  className="text-[#9b99ad] group-hover:translate-x-2 transition-transform duration-700 hover:text-white"
                >
                  Trending News
                </a>
              </li>
              <li className="group flex items-center font-normal text-[15px] leading-[28px] text-[#9b99ad] transition-transform duration-700">
                <FaHeart className="text-transparent transition-colors duration-700 group-hover:text-[#ED6221]" />
                <a
                  href="/"
                  className="text-[#9b99ad] group-hover:translate-x-2 transition-transform duration-700 hover:text-white"
                >
                  Causes
                </a>
              </li>
            </ul>
          </div>

          {/* Quick Link Section */}
          <div>
            <h2 className="text-[20px] font-medium leading-[24px] text-white mb-5">
              Quick Link
            </h2>
            <ul className="flex flex-col space-y-2 -space-x-4">
              <li></li>
              <li className="group flex items-center font-normal text-[15px] leading-[28px] text-[#9b99ad] transition-transform duration-700">
                <FaHeart className="text-transparent transition-colors duration-700 group-hover:text-[#ED6221]" />
                <a
                  href="/"
                  className="text-[#9b99ad] group-hover:translate-x-2 transition-transform duration-700 hover:text-white"
                >
                  Private Policies
                </a>
              </li>
              <li className="group flex items-center font-normal text-[15px] leading-[28px] text-[#9b99ad] transition-transform duration-700">
                <FaHeart className="text-transparent transition-colors duration-700 group-hover:text-[#ED6221]" />
                <a
                  href="/"
                  className="text-[#9b99ad] group-hover:translate-x-2 transition-transform duration-700 hover:text-white"
                >
                  Donate Now
                </a>
              </li>
              <li className="group flex items-center font-normal text-[15px] leading-[28px] text-[#9b99ad] transition-transform duration-700">
                <FaHeart className="text-transparent transition-colors duration-700 group-hover:text-[#ED6221]" />
                <a
                  href="/"
                  className="text-[#9b99ad] group-hover:translate-x-2 transition-transform duration-700 hover:text-white"
                >
                  Become a Volunteer
                </a>
              </li>
              <li className="group flex items-center font-normal text-[15px] leading-[28px] text-[#9b99ad] transition-transform duration-700">
                <FaHeart className="text-transparent transition-colors duration-700 group-hover:text-[#ED6221]" />
                <a
                  href="/"
                  className="text-[#9b99ad] group-hover:translate-x-2 transition-transform duration-700 hover:text-white"
                >
                  Careers
                </a>
              </li>
              <li className="group flex items-center font-normal text-[15px] leading-[28px] text-[#9b99ad] transition-transform duration-700">
                <FaHeart className="text-transparent transition-colors duration-700 group-hover:text-[#ED6221]" />
                <a
                  href="/"
                  className="text-[#9b99ad] group-hover:translate-x-2 transition-transform duration-700 hover:text-white"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>

          {/* Newsletter Section */}
          <div>
            <h2 className="text-[20px] font-medium leading-[24px] text-white mb-8">
              Newsletter
            </h2>
            <p className="font-normal text-[15px] leading-[28px] text-[#9b99ad] mb-4">
              Subscribe us and get latest news &amp; upcoming events.
            </p>
            <div className="flex flex-col">
              <input
                type="email"
                placeholder="Email Address..."
                className="bg-transparent border-[1px] border-[#423F5F] px-3 py-4 rounded-md mb-2 text-[#56546B] placeholder:text-[#56546B] focus:outline-none"
              />
              <button className="text-[#ED6221] text-[13px] font-medium leading-[28px] py-2 pr-1 w-fit">
                <FaArrowRight className="inline-block mr-2" /> SUBSCRIBE US
              </button>
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="mt-8 pt-8 border-t border-gray-700 flex flex-col md:flex-row justify-between items-center">
          <div className="flex flex-col md:flex-row items-start mb-4 md:mb-0">
            <img
              src={Logo}
              alt="ValueWings Logo"
              className="h-[55px] w-[70px] mr-0 md:mr-4 mb-4 md:mb-0"
            />
            <div className="font-light text-[16px] leading-[28px] text-[#9b99ad]">
              <p>
                © 2002-2024 All Rights Reserved by{" "}
                <span className="text-white">VALUEWINGS</span>{" "}
              </p>
              <p>
                Designed By <span className="text-white">NAVIS HR</span>
              </p>
            </div>
          </div>
          <div className="flex space-x-3 mt-4 md:mt-0 cursor-pointer">
            <div className="h-12 w-12 flex items-center justify-center border border-[#423F5F] rounded-full hover:bg-[#ED6221] text-[#9e9bc6] hover:text-white duration-500">
              <FaFacebookF className="h-4 w-4 " />
            </div>
            <div className="h-12 w-12 flex items-center justify-center border border-[#423F5F] rounded-full hover:bg-[#ED6221] text-[#9e9bc6] hover:text-white duration-500">
              <FaTwitter className="h-4 w-4" />
            </div>
            <div className="h-12 w-12 flex items-center justify-center border border-[#423F5F] rounded-full hover:bg-[#ED6221] text-[#9e9bc6] hover:text-white duration-500">
              <FaInstagram className="h-5 w-5" />
            </div>
            <div className="h-12 w-12 flex items-center justify-center border border-[#423F5F] rounded-full hover:bg-[#ED6221] text-[#9e9bc6] hover:text-white duration-500">
              <FaLinkedinIn className="h-4 w-4" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
