import { motion } from "framer-motion";
import React, { useState } from "react";
import { createSlideAnimation } from "../animations/animations";
import CSRImage1 from "../assets/csr-events/csr-1.jpg";
import CSRImage2 from "../assets/csr-events/csr-2.jpg";
import CSRImage3 from "../assets/csr-events/csr-3.jpg";
import CSRImage4 from "../assets/csr-events/csr-4.jpg";
import CSRImage5 from "../assets/csr-events/csr-5.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import { IoMdClose } from "react-icons/io";
import { RiCalendarEventFill } from "react-icons/ri";

const ImageDialog: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  imageSrc: string;
  allImages: string[];
}> = ({ isOpen, onClose, imageSrc, allImages }) => {
  if (!isOpen) return null;

  const initialSlide = allImages.indexOf(imageSrc);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 cursor-pointer px-2">
      <div className="bg-white rounded-lg w-full max-w-4xl max-h-[90vh] overflow-hidden relative">
        <Swiper initialSlide={initialSlide} loop={true} className="h-full">
          {allImages.map((img, index) => (
            <SwiperSlide key={index} className="h-full">
              <div className="flex items-center justify-center">
                <img
                  src={img}
                  alt={`Enlarged view ${index + 1}`}
                  className="w-full h-full object-cover "
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-white bg-gray-400 bg-opacity-50 hover:bg-opacity-75 rounded-full p-2 transition-colors duration-200 z-10"
          aria-label="Close"
        >
          <IoMdClose size={24} />
        </button>
      </div>
    </div>
  );
};

const CSREvents: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const allImages = [CSRImage1, CSRImage2, CSRImage3, CSRImage4, CSRImage5];

  return (
    <div className="pb-28 md:pb-32">
      <div className="relative bg-[#1d1747e6] text-white py-28">
        <div className="text-center mb-12">
          <motion.h1
            className="font-prata font-normal text-[32px] md:text-[48px] leading-[58px]"
            {...createSlideAnimation("bottom", 0.2)}
          >
            Key Moments
          </motion.h1>
        </div>
        <div className="absolute w-[205px] h-[63px] bottom-0 left-6 md:left-11 transform translate-y-1/2 bg-white py-2 px-4 rounded shadow-md flex items-center justify-center">
          <span className="text-[#ff6b35] mr-2 text-[24px]">
            <RiCalendarEventFill />
          </span>
          <div className="w-px h-7 bg-[#EAEAEA] mx-4"></div>
          <span className="font-normal text-[16px] leading-[28px] text-[#1d1747e6] mt-2">
            CSR Events
          </span>
        </div>
      </div>

      <div className="px-5 md:px-11 pt-28">
        <div className=" py-2 flex flex-col md:flex-row ">
          <div className="md:w-1/2 md:h-2/3 mb-8 md:mb-0">
            <div className="mb-4">
              <img
                src={CSRImage1}
                alt="Key Moment"
                className="w-full h-auto rounded-lg shadow-lg cursor-pointer"
                onClick={() => setSelectedImage(CSRImage1)}
              />
            </div>
            <div className="mt-4 shadow-md cursor-pointer">
              <Swiper
                modules={[Autoplay]}
                slidesPerView={3}
                spaceBetween={10}
                autoplay={{ delay: 2000, disableOnInteraction: false }}
                loop={true}
              >
                {[CSRImage2, CSRImage3, CSRImage4, CSRImage5].map(
                  (img, index) => (
                    <SwiperSlide key={index}>
                      <img
                        src={img}
                        alt={`Key Moment ${index + 2}`}
                        className="w-full h-[80px] sm:h-[80px] md:h-[80px] lg:h-[120px] rounded-lg shadow-xl cursor-pointer
                        transition duration-300 ease-in-out transform hover:scale-95 "
                        onClick={() => setSelectedImage(img)}
                      />
                    </SwiperSlide>
                  )
                )}
              </Swiper>
            </div>
          </div>
          <div className="md:w-1/2 md:pl-8 mt-px ">
            <h3 className="text-xl sm:text-2xl md:text-3xl lg:text-[30px] font-normal leading-tight sm:leading-snug md:leading-normal lg:leading-[36px] text-[#302c51] mb-10 font-prata">
              Empowering Underprivileged Youth through Japanese Language
              Training
            </h3>
            <p className="text-[15px] leading-[28px] font-normal text-[#858585] mb-4">
              <span className="font-medium text-gray-700">
                Corporate Support:
              </span>{" "}
              Backed by
              <span className="font-medium text-gray-700">
                {" "}
                Mitsui & Co. Pvt Ltd.
              </span>{" "}
              through their CSR initiative, this program is focused on
              upskilling young individuals from marginalized communities,
              providing them with the tools to unlock new career possibilities.
            </p>
            <p className="text-[15px] leading-[28px] font-normal text-[#858585] mb-4">
              <span className="font-medium text-gray-700">
                Career Advancement:
              </span>{" "}
              Graduates of the program are offered exclusive employment
              opportunities in Japan, paving the way for rewarding careers
              abroad.
            </p>
            <p className="text-[15px] leading-[28px] font-normal text-[#858585] mb-4">
              <span className="font-medium text-gray-700">
                Making a Social Impact:
              </span>{" "}
              By addressing the skills gap and fostering inclusion, this
              initiative transforms the lives of students from economically
              disadvantaged backgrounds, equipping them to succeed on the global
              stage.
            </p>
            {/* <p className="text-[15px] leading-[28px] font-normal text-[#858585] mb-4">
            <span className="font-medium">Social Impact:</span> This initiative
            will not only bridge the skills gap but also uplift students from
            economically weaker sections, giving them the tools they need to
            succeed in the global marketplace.
          </p> */}
          </div>
        </div>

        <ImageDialog
          isOpen={!!selectedImage}
          onClose={() => setSelectedImage(null)}
          imageSrc={selectedImage || ""}
          allImages={allImages}
        />
      </div>
    </div>
  );
};

export default CSREvents;
