import React, { useRef, useEffect } from "react";
import TopBar from "../component/TopBar";
import NavBar from "../component/NavBar";
import SwiperSlide from "../component/SwiperSlide";
import Section1 from "../component/Section1";
import Section2 from "../component/Section2";
import Causes from "../component/Causes";
import Contact from "../component/Contact";
import Footer from "../component/Footer";
import KeyMoments from "../component/KeyMoments";
import { useLocation } from "react-router-dom";

const Home: React.FC = () => {
  const location = useLocation();
  const causesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (location.state && location.state.scrollTo === "causes") {
      const causesElement = document.getElementById("causes");
      if (causesElement) {
        causesElement.scrollIntoView({ behavior: "smooth" });
      }
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  useEffect(() => {
    if (window.location.hash === "#causes" && causesRef.current) {
      causesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div>
      <TopBar />
      <NavBar />
      <main>
        <SwiperSlide />
      </main>
      <section id="about-us">
        <Section1 />
        <Section2 />
      </section>
      <section id="causes" ref={causesRef}>
        <Causes />
      </section>
      <section id="key-moments">
        <KeyMoments />
      </section>
      <section id="contact-us">
        <Contact />
      </section>
      <Footer />
    </div>
  );
};

export default Home;
