import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";
import type { Swiper as SwiperType } from "swiper";
import { motion, AnimatePresence } from "framer-motion";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import image1 from "../assets/slider/Slider1.jpg";
import image2 from "../assets/slider/Slider2.jpg";
import image3 from "../assets/slider/Slider3.jpg";
import image4 from "../assets/slider/Slider4.jpg";
import image5 from "../assets/slider/Slider5.jpg";
import image6 from "../assets/slider/Slider6.jpg";
import { PiCaretLeftBold, PiCaretRightBold } from "react-icons/pi";
import {
  slideContentVariants,
  subtitleVariants,
  titleVariants,
  descriptionVariants,
  buttonVariants,
} from "../animations/slideAnimations";

// Define the structure for slide data
interface SlideData {
  title: string;
  subtitle: string;
  description: string;
  buttonText: string;
  backgroundImage: string;
}

// Slides data array
const slides: SlideData[] = [
  {
    title: "Childrens out of school",
    subtitle: "12.97 million out of 36 million Indians",
    description:
      "To improve the learning environment in primary schools by holistically creating world-class basic education systems to the community.",
    buttonText: "Donate Now",
    backgroundImage: image1,
  },
  {
    title: "Support our cause",
    subtitle: "Your Donation Helps Us Reach People in Need",
    description: "",
    buttonText: "Donate Now",
    backgroundImage: image2,
  },
  {
    title: "MITSUI & CO. INDIA PVT LTD ",
    subtitle: "1st CSR Project by",
    description: "",
    buttonText: "Donate Now",
    backgroundImage: image3,
  },
  {
    title: "WHY CSR?",
    subtitle: "Corporate Social Responsibility",
    description:
      "As a corporate you can fulfil your social responsibilities by investing in initiatives that address the Socio-economic challenges faced by youth, particularly in terms of employability and skill development.",
    buttonText: "Donate Now",
    backgroundImage: image4,
  },
  {
    title: "Together we can make a Difference",
    subtitle: "To improve learning environment in the country",
    description: "",
    buttonText: "Donate Now",
    backgroundImage: image5,
  },
  {
    title: "Healing & Caring",
    subtitle: "",
    description:
      "To improve the learning environment for nurses across India holistically creating world-class professional.",
    buttonText: "Donate Now",
    backgroundImage: image6,
  },
];

const SwiperSlider: React.FC = () => {
  const swiperRef = useRef<SwiperType>();
  const [activeIndex, setActiveIndex] = useState(0);

  // Handle previous slide
  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
      if (swiperRef.current.activeIndex === slides.length - 1) {
        swiperRef.current.slideTo(0);
      }
    }
  };

  // Handle next slide
  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
      if (swiperRef.current.activeIndex === slides.length + 1) {
        swiperRef.current.slideTo(0);
      }
    }
  };

  return (
    <div className="relative w-full h-[50vh] sm:h-[60vh] md:h-[70vh] lg:h-[80vh] xl:h-screen">
      {/* Custom styles for Swiper pagination */}
      <style>{`
        .swiper-pagination-bullet {
          background-color: white;
          width: 10px;
          height: 10px;
        }
        .swiper-pagination-bullet-active {
          opacity: 1;
        }
      `}</style>

      {/* Swiper component */}
      <Swiper
        modules={[Navigation, Pagination, Autoplay, EffectFade]}
        spaceBetween={0}
        slidesPerView={1}
        pagination={{ clickable: true, el: ".swiper-pagination" }}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        loop={true}
        effect="fade"
        onBeforeInit={(swiper: SwiperType) => {
          swiperRef.current = swiper;
        }}
        onSlideChange={(swiper: SwiperType) => setActiveIndex(swiper.realIndex)}
        className="w-full h-full"
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            {/* Slide content */}
            <div
              className="w-full h-full bg-no-repeat bg-cover bg-center flex flex-col justify-center items-center text-white text-center"
              style={{
                backgroundImage: `url(${slide.backgroundImage})`,
              }}
            >
              <AnimatePresence mode="wait">
                {activeIndex === index && (
                  <motion.div
                    key={index}
                    variants={slideContentVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={{ duration: 0.5 }}
                    className="space-y-2 sm:space-y-4 max-w-5xl px-4"
                  >
                    {/* Subtitle */}
                    {slide.subtitle && (
                      <motion.h3
                        variants={subtitleVariants}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="font-[500] text-white text-[18px] leading-[26px] uppercase flex items-center justify-center"
                      >
                        {/* Decorative lines */}
                        <div className="hidden sm:flex flex-col mr-5 items-end">
                          <span className="w-16 h-[2px] bg-[#ed6221] mb-1"></span>
                          <span className="w-32 h-[2px] bg-[#ed6221]"></span>
                        </div>
                        {slide.subtitle}
                        <div className="hidden sm:flex flex-col ml-5">
                          <span className="w-16 h-[2px] bg-[#ed6221] mb-1"></span>
                          <span className="w-32 h-[2px] bg-[#ed6221]"></span>
                        </div>
                      </motion.h3>
                    )}

                    {/* Title */}
                    <motion.h2
                      variants={titleVariants}
                      transition={{ duration: 0.6 }}
                      className="text-4xl sm:text-5xl md:text-6xl lg:text-[72px] font-[500] text-white uppercase"
                    >
                      {slide.title}
                    </motion.h2>

                    {/* Description */}
                    <motion.p
                      variants={descriptionVariants}
                      transition={{ duration: 0.6, delay: 0.4 }}
                      className="font-normal text-white text-sm sm:text-base md:text-lg lg:text-[18px] leading-[22px] px-1 sm:px-26 md:px-[7.8rem]"
                    >
                      {slide.description}
                    </motion.p>

                    {/* Button */}
                    <motion.button
                      variants={buttonVariants}
                      transition={{ duration: 0.6, delay: 0.6 }}
                      className="mt-2 sm:mt-4 px-4 sm:px-6 md:px-10 py-2 sm:py-3 md:py-4 bg-transparent border-2 border-[#ed6221] rounded-full font-[500] text-xs sm:text-sm md:text-[15px] relative overflow-hidden group"
                    >
                      <span className="relative z-10 text-[#ed6221] group-hover:text-white transition-colors duration-300">
                        {slide.buttonText}
                      </span>
                      <span className="absolute inset-0 bg-[#ed6221] transform -translate-y-full group-hover:translate-y-0 transition-transform duration-300"></span>
                    </motion.button>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Previous slide button */}
      <div
        className="absolute top-1/2 left-2 sm:left-4 md:left-6 w-10 h-10 sm:w-16 sm:h-16 md:w-20 md:h-20 bg-white bg-opacity-10 rounded-full hidden sm:flex justify-center items-center cursor-pointer z-10 transform -translate-y-1/2 hover:bg-opacity-20 transition-all duration-300"
        onClick={handlePrev}
      >
        <PiCaretLeftBold className="w-4 h-4 sm:w-6 sm:h-6 md:w-8 md:h-8 text-white hover:text-[#ed6221] transition-colors duration-300" />
      </div>

      {/* Next slide button */}
      <div
        className="absolute top-1/2 right-2 sm:right-4 md:right-6 w-10 h-10 sm:w-16 sm:h-16 md:w-20 md:h-20 bg-white bg-opacity-10 rounded-full hidden sm:flex justify-center items-center cursor-pointer z-10 transform -translate-y-1/2 hover:bg-opacity-20 transition-all duration-300"
        onClick={handleNext}
      >
        <PiCaretRightBold className="w-4 h-4 sm:w-6 sm:h-6 md:w-8 md:h-8 text-white hover:text-[#ed6221] transition-colors duration-300" />
      </div>

      {/* Pagination dots */}
      <div className="swiper-pagination absolute bottom-4 left-0 right-0 z-10 flex justify-center items-center gap-1 "></div>
    </div>
  );
};

export default SwiperSlider;
