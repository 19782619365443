import React from "react";
import TopBar from "../component/TopBar";
import NavBar from "../component/NavBar";
import Footer from "../component/Footer";
import CSREvents from "../component/CSR-events";

const KeyMomentsPage: React.FC = () => {
  return (
    <div>
      <TopBar />
      <NavBar />
      <CSREvents />
      <Footer />
    </div>
  );
};

export default KeyMomentsPage;
