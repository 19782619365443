import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import AboutUsPage from "../pages/About-us";
import ContactPage from "../pages/Contact";
import KeyMomentsPage from "../pages/KeyMoments";

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<AboutUsPage />} />
      <Route path="/contact-us" element={<ContactPage />} />
      <Route path="/key-moments" element={<KeyMomentsPage />} />
    </Routes>
  );
};

export default AppRoutes;
