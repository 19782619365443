import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { motion } from "framer-motion";
import { createSlideAnimation } from "../animations/animations";

const Section2: React.FC = () => {
  return (
    <div className="flex flex-col gap-6 max-w-6xl mx-auto justify-center px-4 py-12 ">
      <div className="flex flex-col md:flex-row gap-6 ">
        <motion.div
          className="flex-1 w-full bg-white p-4 rounded-lg shadow-[0_0_10px_rgba(237,98,33,0.3)]"
          {...createSlideAnimation("bottom")}
        >
          {/* Our Mission content */}
          <button className="w-full bg-[#ed6221] text-white font-bold py-4 px-6 rounded-lg shadow-md transition duration-300 mb-4 flex items-center justify-center relative overflow-hidden group">
            <span className="absolute inset-0 bg-[#302C51] transform -translate-x-full group-hover:translate-x-0 transition-transform duration-300 ease-in-out"></span>
            <FaArrowRight className="mr-2 relative z-10" />
            <span className="font-normal text-lg leading-6 relative z-10">
              Our Mission
            </span>
          </button>
          <p className="text-[#858585] leading-relaxed px-2 md:px-1 md:text-left">
            Provide accessible and affordable education to under privileged
            communities & elevating individuals with skills & Knowledge to
            improve their livelihood
          </p>
        </motion.div>

        <motion.div
          className="flex-1 w-full bg-white p-4 rounded-lg shadow-[0_0_10px_rgba(237,98,33,0.3)]"
          {...createSlideAnimation("bottom", 0.2)}
        >
          {/* Our Vision content */}
          <button className="w-full bg-[#ed6221] text-white font-bold py-4 px-6 rounded-lg shadow-md transition duration-300 mb-4 flex items-center justify-center relative overflow-hidden group">
            <span className="absolute inset-0 bg-[#302C51] transform -translate-x-full group-hover:translate-x-0 transition-transform duration-300 ease-in-out"></span>
            <FaArrowRight className="mr-2 relative z-10" />
            <span className="font-normal text-lg leading-6 relative z-10">
              Our Vision
            </span>
          </button>
          <p className="text-[#858585] leading-relaxed px-2 md:px-1 md:text-left">
            Transforming lives and enriching communities by fostering essential
            skills, ensuring families can support themselves with honor and
            aspire to a brilliant future.
          </p>
        </motion.div>

        <motion.div
          className="flex-1 w-full bg-white p-4 rounded-lg shadow-[0_0_10px_rgba(237,98,33,0.3)]"
          {...createSlideAnimation("bottom", 0.4)}
        >
          {/* Our Values content */}
          <button className="w-full bg-[#ed6221] text-white font-bold py-4 px-6 rounded-lg shadow-md transition duration-300 mb-4 flex items-center justify-center relative overflow-hidden group">
            <span className="absolute inset-0 bg-[#302C51] transform -translate-x-full group-hover:translate-x-0 transition-transform duration-300 ease-in-out"></span>
            <FaArrowRight className="mr-2 relative z-10" />
            <span className="font-normal text-lg leading-6 relative z-10">
              Our Values
            </span>
          </button>
          <p className="text-[#858585] leading-relaxed px-2 md:px-1 md:text-left">
            Integrity, Compassion, Innovation and commitment to excellence
          </p>
        </motion.div>
      </div>

      {/* More About Us button */}
      <motion.div
        className="flex-1 w-full"
        {...createSlideAnimation("bottom", 0.6)}
      >
        <div className="w-full flex justify-start px-3 mt-4">
          <button className="px-9 py-4 border-2 border-[#ed6221] rounded-full font-[500] text-[13px] relative overflow-hidden group">
            <span className="relative z-10 text-[#ed6221] group-hover:text-white transition-colors duration-300">
              MORE ABOUT US
            </span>
            <span className="absolute inset-0 bg-[#ed6221] transform -translate-y-full group-hover:translate-y-0 transition-transform duration-300"></span>
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default Section2;
